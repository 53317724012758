import React from "react";
import classes from "./SfxPlayer.module.css";




function _SfxPlayer() {
    return (
        <div id="sfx_Player" className={classes.root}>

        </div>
    );
}
export const SfxPlayer = React.memo(_SfxPlayer);

const _map = {};

export function Play({src, loop = false, singleton = true}){
    if(!singleton){
        let aud = new Audio(src);
        aud.play();
        return;
    }

    if(!_map[src]){
        _map[src] = new Audio(src);
    }
    _map[src].currentTime = 0;
    _map[src].play();
    _map[src].loop = loop;
}

export function Preload({src}){
    if(!_map[src]){
        _map[src] = new Audio(src);
        _map[src].preload = "auto";
        _map[src].load();
    }
    return null;
}