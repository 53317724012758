import { useEffect, useState } from "react";
import classes from "./StartupVersionInfo.module.css";
import Icon from "../Icon";

let to;
export default function StartupVersionInfo() {
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(true);
  useEffect(() => {

    fetch("builddetails.json").then(async (res) => {
      const rText = await res.text();
      try{
        const rJson = JSON.parse(rText);
        if(rJson){
          return rJson;
        }
      }
      catch(e){
        console.error(e);
      }
      return null;
    }).then((data) => {
      setData(data);
      clearTimeout(to);
      to = setTimeout(()=>{
        setVisible(false);
      }, window.device != "pc" ? 4000 : 500);
    });
  },[]);


  if(!visible)
    return null;

  return (

    <div className={classes.root}>
      <h1>Runtime version:</h1>
      <p>
        Build version: {data?.version || "unknown"}<br/>
        Build time: {data?.ts ? new Date(data.ts).toLocaleString() : "unknown"}
      </p>
      <div className={classes.close} onClick={()=>setVisible(false)}><Icon type="close"></Icon></div>
    </div>
  );
}
