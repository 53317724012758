import { createSlice } from '@reduxjs/toolkit'
import { SetLanguage } from '../utils/LanguageString';


export const appSlice = createSlice({
  name: 'app',
  initialState: {
    app:null,
    language:"de",
    appDb:null,
    idleMode:false,
    debug:document.location.search?.indexOf("debug=1") > -1,
  },
  reducers: {
    toggleLanguage: (state) => {
      state.language = state.language==="de"?"en":"de";
      document.documentElement.setAttribute('lang', state.language);
      SetLanguage(state.language);
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
      document.documentElement.setAttribute('lang', state.language);
      SetLanguage(state.language);
    },

    setApp: (state, action) => {
      state.app = action.payload;
    },
    setDbHash: (state, action) =>{
      state.appDbHash = action.payload;
    },

    idleOn: (state) => {
      state.idleMode = true;
    },
    idleOff: (state) => {
      state.idleMode = false;
    },
    setDebug: (state, action) => {
      state.debug = action.payload;
    }
  },
})
export const {setApp, setLanguage, toggleLanguage, setDbHash, idleOff, idleOn, setDebug} = appSlice.actions

export default appSlice.reducer