import { LanguageString } from "../../utils/LanguageString";

let db = null;

export function ParseDB(raw, rawLang){
  let db = new DB(raw);


  console.log("DB", raw, rawLang);
  db.lang = {};
  for(let i in rawLang){
    db.lang[i] = new LanguageString(rawLang[i]);
  }

  for(let i in raw.lang){
    db.lang[i] = new LanguageString(raw.lang[i]);
  }



  return db;
}
class DB{
  constructor(raw, rawLang){
    this.settings = raw.settings;
    this.video = raw.video;
    this.menu_icon = raw.menu_icon;
    this.idle = {
      bg: raw.idle.bg,
      btn: new LanguageString(raw.idle.btn)
    }
    this.subtitles = new LanguageString(raw.subtitles);
  }
}