import { useEffect, useState } from "react";
import classes from "./AudioGuide.module.css";


export const STATES = {
  PLAYING: "playing",
  IDLE: "idle"
}
export function SendStatus({station, status, ts, language, src, key}){
  ts = Math.round(ts*10)/10;
  //console.log(station, status, ts, language, src, key);
}


//SEEMS TO BE DEPRECATED

export default function AudioGuide() {
  const [data, setData] = useState(null);

  useEffect(() => {
    return;
    fetch(process.env.REACT_APP_TEST).then(async (res) => {
      const rText = await res.text();
      try{
        const rJson = JSON.parse(rText);
        if(rJson){
          return rJson;
        }
      }
      catch(e){
        console.error(e);
      }
      return null;
    }).then((data) => {
      setData(data);
    });
  },[]);

  console.log(data);
  return null;
  return (

    <div className={classes.root}>
      AudioGuide
      {process.env.REACT_APP_TEST}
    </div>
  );
}
