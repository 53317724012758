import { useEffect, useRef, useState } from 'react';
import classes from './OperatorOverlay.module.css';
import { useSelector } from 'react-redux';
import { send } from './../../../components/HtmlKioskBridge/HtmlKioskBridge';



// #region load localStoragee
let udpInitialData = localStorage.getItem("udp");
try{
  udpInitialData = JSON.parse(udpInitialData);
  if(!udpInitialData)
    udpInitialData = {};

}catch(e){}

if(!udpInitialData.udpIp){
  udpInitialData.udpIp = "192.168.178.255"
}
if(!udpInitialData.udpPort){
  udpInitialData.udpPort = "12345"
}
if(!udpInitialData.token){
  udpInitialData.token = ""
}

let lastKnownDevices = localStorage.getItem("lastKnownDevices");
try{
  lastKnownDevices = JSON.parse(lastKnownDevices);
  if(!lastKnownDevices || !Array.isArray(lastKnownDevices))
    lastKnownDevices = [];
}catch(e){
  lastKnownDevices = [];
}
// #endregion load localStoragee

export default  function OperatorOverlay({stations, udp, openedStation, onStationClosed}) {

  const app = useSelector((state) => state.app?.app);
  const [devices, setDevices] = useState(lastKnownDevices);
  const [udpIp, setUdpIp] = useState(udpInitialData.udpIp);
  const [udpPort, setUdpPort] = useState(udpInitialData.udpPort);
  const [token, setToken] = useState(udpInitialData.token);
  const [deviceEditable, setDeviceEditable] = useState(null);
  useEffect(()=>{
    requestPing();
  },[]);
  useEffect(()=>{
    localStorage.setItem("lastKnownDevices", JSON.stringify(devices));
  },[devices]);
  useEffect(()=>{
    //console.log("udp", udp);
    if(udp?.ts && udp?.data){
      try{
        const data = JSON.parse(udp.data);
        if(data?.action){

          if(data.action === "ping"){

            let o = {
              ips: data.value?.device?.actualIps,
              model: data.value?.device?.model,
              serial: data.value?.device?.serial,
              os: data.value?.device?.os,
              ts: udp.ts,
              station: data.value?.app?.station,
              online:true
            };

            if(!devices.find(e=>e.ts === o.ts)){
              let newDevices = devices.filter(e=>e.serial !== o.serial);
              setDevices([...newDevices, o]);
            }
            //
            /*
            */
          }
        }

      }catch(e){
        console.log(e);
      }
    }
  },[udp, setDevices, devices]);
  useEffect(()=>{
    localStorage.setItem("udp", JSON.stringify({udpIp, udpPort, token}));
  },[udpIp, udpPort, token]);

  const createPayload = (action, to)=>{
    const payload = {
      payload:{
        msg:JSON.stringify({"action":action}),
        address:udpIp,
        port:udpPort
      }
    };
    if(to)
      payload.payload.to = to;
    if(action === "reboot")
      payload.payload.token = token;

    return payload;

  }
  const sendReboot = (deviceId)=>{
    const payload = createPayload("reboot", deviceId);
    send("udp", {payload});
  }
  const sendFactoryReset = (deviceId)=>{
    if(!deviceId)
        return;

    const payload = createPayload("factoryReset", deviceId);
    payload.payload.msg = JSON.stringify({"action":"factoryReset", value:"1", token:token});
    console.log("deactivated", payload)
    return;
    send("udp", {payload});
  }
  const showAdmin = (deviceId, visible=true, withPin=false)=>{
    const action = "admin";
    const payload = createPayload(action, deviceId);
    payload.payload.msg = JSON.stringify({"action":action, value:visible, token:visible && withPin?token:'', withPin:withPin});
    send("udp", {payload});
  }
  const openUrl = (deviceId, url)=>{
    const action = "showUrl";
    const payload = createPayload(action, deviceId);
    payload.payload.msg = JSON.stringify({"action":action, to:deviceId, token:token, url:url});


    send("udp", {payload});
  }

  const showInfo = (deviceId, visible=true)=>{
    const action = visible?"showInfo":"hideInfo";
    const payload = createPayload(action, deviceId);
    send("udp", {payload});
  }
  const requestPing = (deviceId)=>{
    if(deviceId){
      const device = devices.find(e=>e.serial === deviceId);
      if(device){
        device.online = false;
        setDevices([...devices]);
      }

    }else{
      devices.forEach(e=>e.online = false);
      setDevices([...devices]);
    }
    setTimeout(()=>{
      const action = "sendInfo";
      const payload = createPayload(action, deviceId);
      send("udp", {payload});
    }, 100);
  }
  const sendReload = (deviceId)=>{
    const payload = createPayload("reload", deviceId);
    send("udp", {payload});
  }
  const sendClearCache = (deviceId)=>{
    const payload = createPayload("clearCache", deviceId);
    send("udp", {payload});
  }
  const setApp = (deviceId, appId)=>{
    const action = "setApp";
    const payload = createPayload(action, deviceId);
    payload.payload.msg = JSON.stringify({"action":action, to:deviceId, value:appId, token:token});
    send("udp", {payload});
  }


  const sendSetApp = (deviceId, app)=>{
    //ev1.target.value
    console.log("sendSetApp", deviceId, app);
    setApp(deviceId, app);
    setTimeout(()=>requestPing(), 1000);
    setDeviceEditable(null);

  }


  const deleteDevice = (serial)=>{
    const newDevices = devices.filter(e=>e.serial !== serial);
    setDevices(newDevices);
  }

  let device = devices.find(e=>e.station?.id === openedStation?.id);
  devices.sort((a,b)=>b.ts-a.ts);
  return (
    <div className={classes.root} >
      <div className={classes.mainMenu}>
        <h1>Operator Overlay</h1>
        <input type="text" placeholder="udp address" defaultValue={udpIp} onInput={(e)=>setUdpIp(e.target.value)} />
        <input type="text" placeholder="udp port" defaultValue={udpPort} onInput={(e)=>setUdpPort(e.target.value)} />
        <input type="text" placeholder="sec token" defaultValue={token} onInput={(e)=>setToken(e.target.value)} />
        <div className={classes.entries}>
          <h3>Actions to all devices</h3>
          <div className={classes.btn} onClick={()=>{requestPing()}} title="">Request ping</div>
          <div className={classes.btn} onClick={()=>{}} title="">Reboot all devices</div>
          <div className={classes.btn} onClick={()=>{showAdmin(null, true, false)}} title="">Admin open</div>
          <div className={classes.btn} onClick={()=>{showAdmin(null, false)}} title="">Admin close</div>
          <div className={classes.btn} onClick={()=>{showInfo(null, true)}} title="">Show info</div>
          <div className={classes.btn} onClick={()=>{showInfo(null, false)}} title="">Hide info</div>
          <div className={classes.btn} onClick={()=>{sendReload()}} title="">Reload</div>
          <div className={classes.btn} onClick={()=>{sendClearCache()}} title="">Clear cache</div>

        </div>
      </div>
      <div className={classes.devices}>
        <h2>Devices</h2>
        <div className={classes.entries}>
          {devices.map((e,i)=>
            <div key={i} className={classes.device}>
              <p className={e.online?classes.online:classes.offline}>{e.online?"online":"offline"}</p>
              <p>{e.serial}</p>
              <p>{e.model} - {e.os}</p>
              <p></p>
              <p className='selectable'>{e.ips?.join(", ")}</p>
              <p>last seen: {new Date(e.ts).toLocaleString()}</p>
              {e.station && <p>Station: {e.station.id} {e.station.name}</p>}
              {deviceEditable != e && <div className={classes.btnSmall} onClick={()=>setDeviceEditable(e)}>set station</div>}
              {deviceEditable === e && <div>
                <h3>Select a station</h3>
                <select onChange={(ev1)=>{sendSetApp(e.serial, ev1.target.value)}}>
                  <option value="">---</option>
                  {Object.values(stations).map((e1,i1)=>
                    <option key={e1.id+":"+i1} value={e1.id}>{e1.id} {e1.name}</option>
                  )}
                </select>
              </div>}

              {new Date().getTime()-e.ts>10000 && <><br/><br/>
              <hr/><br/><div className={classes.btnSmall} onClick={()=>deleteDevice(e.serial)}>delete</div><small>remove from list, will be added if the device sends an alive info</small></>}

            </div>
          )
          }
        </div>
      </div>
      {openedStation && <div className={classes.stationMenu}>
        <div>
          <div className={classes.closeBtn} onClick={onStationClosed}>Close</div>
          <h2>Station {openedStation.id}  {openedStation.name}</h2>
          <p>cms id: {openedStation.cms_id}</p>
          <p>path: <span className='selectable'>#{openedStation.hash}</span></p>
          <p>path: <span className='selectable'>/?debug=1#{openedStation.hash}</span></p>
          <p>Player: {openedStation.player}</p>
          <p>Display: {openedStation.display}</p>
          <a href={`/?debug=1#${openedStation.hash}`} target="_blank">show app</a>
          {device?<div className={classes.device}>
            <h3>Device</h3>
            <p className={device.online?classes.online:classes.offline}>{device.online?"online":"offline"}</p>

            <p>last update: {new Date(device.ts).toLocaleString()}</p>
            <p>model: {device.model}</p>
            <p>os: {device.os}</p>
            <p>serial: <span className='selectable'>{device.serial}</span></p>
            <p>ips: <span className='selectable'>{device.ips?.join(", ")}</span></p>
            {openedStation.player === "Brightsign" && device.ips?.map((e,i)=>
              <>
                <a key={i} className={classes.btnSmall} onClick={(e1)=>{
                  e1.preventDefault();
                  navigator.clipboard.writeText(device.serial);
                  window.open("http://"+e, "_blank");
                }} href={"http://"+e} target="_blank">
                  open BS page ({e})
                </a>
                <a key={i+"a"} className={classes.btnSmall} href={"http://"+e+":2999"} target="_blank">
                open inspector ({e})
                </a>
              </>
            )
            }
            <div className={classes.actions}>
              <h4>Actions:</h4>
              <div>
                <div className={classes.btnSmall} onClick={()=>{requestPing(device.serial)}} title="">Request ping</div>
                <div className={classes.btnSmall} onClick={()=>{sendReboot(device.serial)}} title="">Reboot</div>
                <div className={classes.btnSmall} onClick={()=>{showAdmin(device.serial, true, false)}} title="">Admin open</div>
                <div className={classes.btnSmall} onClick={()=>{showAdmin(device.serial, false)}} title="">Admin close</div>
                <div className={classes.btnSmall} onClick={()=>{showInfo(device.serial, true)}} title="">Show info</div>
                <div className={classes.btnSmall} onClick={()=>{showInfo(device.serial, false)}} title="">Hide info</div>
                <div className={classes.btnSmall} onClick={()=>{sendReload(device.serial)}} title="">Reload</div>
                <div className={classes.btnSmall} onClick={()=>{sendClearCache(device.serial)}} title="">Clear cache</div>

                <br/><br/>
                <div className={classes.btnSmall + " " + classes.alert} onClick={()=>{sendFactoryReset(device.serial)}} title="">Factory reset (deactivated)</div>
                <br/><br/><br/>
                <input id="urlInput" className={classes.urlInput} type="text" placeholder="url" defaultValue={"https://dua.aktuelleprojekte.de?"+"#"+openedStation.hash} />
                <div className={classes.btnSmall} onClick={()=>{openUrl(device.serial,document.getElementById("urlInput").value)}} title="">Open url</div>

              </div>
            </div>


          </div>:<div  className={classes.device}><h3>no device</h3></div>}
        </div>
      </div>
    }
    </div>
  );
}
