import { useEffect, useState } from "react";
import classes from "./ScentTester.module.css";
import Icon from "../Icon";
import { Open } from "../ScentModule";

export default function ScentTester({onClose}) {
  const [settings, setSettings] = useState(JSON.parse(localStorage.getItem("scentTest"))||[]);
  const [state, setState] = useState({startedAt:null, ventil:null });


  if(settings.length === 0){
    for(let i=0;i<=12;i++)
      settings.push(3000);
  }

  useEffect(()=>{
    console.log("settings", settings);
    localStorage.setItem("scentTest", JSON.stringify(settings));
  },[settings]);

  useEffect(()=>{
    if(state.ventil === null)
      return;
    let now = new Date().getTime();
    let duration = settings[state.ventil];
    let progress = (now - state.startedAt) / duration;
    if(progress >= 1){
      setState({startedAt:null, ventil:null, progress:0});
      return;
    }else{
      setTimeout(()=>{
        setState({...state, progress:progress});
      }, 100);
    }
  },[state]);

  const openClicked = (ventil) => {

    if(state.ventil === null){
      Open({channel:ventil, duration:settings[ventil]});
      setState({startedAt:new Date().getTime(), ventil:ventil, progress:0});
      return;
    }
  }
  const getDeck = (deck, start, end) => {

    let ventile = [];
    for(let i=start; i<=end; i++){
      ventile.push(
      <div key={i} className={classes.ventil}>
        <div className={classes.name}>{i}</div>
        <span><input type="number" className={classes.duration} value={settings[i]} onChange={(e)=>{
          let _settings = [...settings];
          _settings[i] = parseInt(e.target.value);
          setSettings(_settings);
        }

      } /> ms
      </span>
        <div className={classes.sprayBtn + " " + (state.ventil===i?classes.active:'')} onClick={(e)=>{openClicked(i)}}
          style={{'--progress':state.progress}}
        >Öffnen</div>
      </div>)
    }
    return <div  className={classes.deck}>
      <h2>ScentDeck {deck}</h2>
      <div className={classes.ventile}>
        {ventile}
      </div>
    </div>
  }

  return (
    <div className={classes.root}>
      <div className={classes.closeBtn}>
        <Icon type="close" onClick={()=>onClose && onClose()}></Icon>
      </div>
      <div className={classes.content + " " + (state.ventil!=null?classes.active:'')}>
        <h1>Duftmodul Tester</h1>
        {getDeck("A", 1, 6)}
        {getDeck("B", 7, 12)}
      </div>
    </div>
  );
}
