import classes from "./Icon.module.css";
import arrow from "../assets/icon_arrow.png";
import close from "../assets/icon_close.png";
import info from "../assets/icon_info.png";
import minus from "../assets/icon_minus.png";
import plus from "../assets/icon_plus.png";
import shift from "../assets/icon_shift.png";
import back from "../assets/icon_back.png";
import edit from "../assets/icon_edit.png";

export default function Icon({ onClick, className = "", type="close" }) {

    let icon = close;
    switch(type){
        case "arrow":
            icon = arrow;
            break;
        case "close":
            icon = close;
            break;
        case "info":
            icon = info;
            break;
        case "minus":
            icon = minus;
            break;
        case "plus":
            icon = plus;
            break;
        case "shift":
            icon = shift;
            break;
        case "back":
            icon = back;
            break;
        case "edit":
            icon = edit;
            break;
    }
    return (
        <div className={"icon " + classes.root + " " + className} onClick={onClick} style={{
            "--icon": `url(${icon})`,
        }}>
        </div>
    );
}
