import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ParseDB } from "./_model/DB";
import classes from "./App_1.module.css";
import { setDbHash, setLanguage, toggleLanguage } from "../_redux/appSlice";
import { SHA256 } from "crypto-js";
import { getMediaPath } from "../utils/generic";
import srtParser2 from "srt-parser-2";
import Icon from "../components/Icon";
import { STATES, SendStatus } from "../components/AudioGuide/AudioGuide";

let subtitles = {};
var parser = new srtParser2();

export default function App_1({ app }) {
    const dispatch = useDispatch();
    const ref = useRef();
    const trackRef = useRef();
    const trackSpanRef = useRef();
    const [subTitlesActivated, setSubTitlesActivated] = useState(null);
    const [idle, setIdle] = useState(true);
    const [videoState, setVideoState] = useState({tc:0, duration:0, src:''});
    const {dbHash, language} = useSelector((state) => state.app);

    const updateProgress = (e) =>{
      setVideoState({...videoState, tc:e.target.currentTime, duration:e.target.duration, src:e.target.src});


      SendStatus( {
        station: app.app.id,
        status: STATES.PLAYING,
        ts: e.target.currentTime,
        language: subTitlesActivated||"de",
        src:window.DB?.video,
        key:"DB.video"
      });


      trackRef.current.classList.remove("hidden");
      updateSubtitles();
    }

    const updateSubtitles = (e) =>{
      if(subTitlesActivated != null && subtitles[ref.current.dataset.src]){
        let langEntries = subtitles[ref.current.dataset.src];

        let list = [];
        if(langEntries[subTitlesActivated])
          list = langEntries[subTitlesActivated];
        else if(Object.values(langEntries).length>0)
          list = Object.values(langEntries)[0];

        for(let i in list){

          if(list[i].startSeconds <= ref.current.currentTime && list[i].endSeconds>=ref.current.currentTime){
            trackSpanRef.current.innerHTML = list[i].text;
          }
        }
      }else{
        trackSpanRef.current.innerHTML = "";
      }
    }

    const clicked = (lang) =>{

      if(subTitlesActivated === lang)
        setSubTitlesActivated(null);
      else
        setSubTitlesActivated(lang);
    }
    const startVideo = () =>{
      if(idle){
        setIdle(false);
        ref.current.play();
      }
    }
    useEffect(() => {
      if(idle){
        ref.current.pause();
        ref.current.currentTime = 0;
        trackSpanRef.current.innerHTML = "";
        dispatch(setLanguage("de"));
        setSubTitlesActivated(null);

        SendStatus( {
          station: app.app.id,
          status: STATES.IDLE,
          ts: 0,
          language: "de",
          src:"",
          key:""
        });

      }
    },[idle]);

    useEffect(() => {
        if (app?.data) {
            let _db = ParseDB(app.data, app.lang);
            window.DB = _db;
            window.disableIdle = true;
            window.contentPath =
                process.env.PUBLIC_URL + "/_content/" + app.app.path + "/";
            const hash = SHA256(JSON.stringify(_db)).toString();
            dispatch(setDbHash(hash));
            setVideoState({tc:0, duration:0, src:window.DB.video});


            subtitles[window.DB.video] = {};
            for(let i in _db.subtitles.entries){
              fetch(getMediaPath(_db.subtitles.entries[i]),  {mode: 'cors'})
              .then((e=>{
                return e.text()
              }))
              .then(function (text) {
                var srt_array = parser.fromSrt(text);
                subtitles[window.DB.video][i] = srt_array;

              });
            }

        }
    }, [app]);


    return (
        <div className={classes.root + " "  + (idle?classes.idle:'')} >
           <video data-src={window.DB?.video}  src={getMediaPath(window.DB?.video)} className={classes.video} onEnded={()=>setIdle(true)} ref={ref} loop={false} autoPlay={false} onTimeUpdate={updateProgress}></video>
           <div className={classes.track + " " + (!subTitlesActivated?classes.hidden:'')} ref={trackRef}>
              <span ref={trackSpanRef}></span>
            </div>
           <div className={classes.progress} style={{'--progress':videoState.tc/videoState.duration}}>{videoState.src} {videoState.playing?"playing":"paused"}</div>
           <div className={classes.langSelect}>
            <div className="langBtnIcon" style={{'--bg':`url(${getMediaPath(window.DB?.menu_icon)}`}}></div>
            <div className={"langBtnDe " + (subTitlesActivated==="de"?classes.activated:'')} onClick={() => clicked("de")}>DE</div>
            <div className={"langBtnEn " + (subTitlesActivated==="en"?classes.activated:'')} onClick={() => clicked("en")}>EN</div>
           </div>
           <div className="cancelBtn btn" onClick={() => setIdle(true)}>
           <Icon type="close"></Icon> {window.DB?.lang?.cancel?.Get()}
           </div>
           <div className={classes.idleView + " "+ (!idle?classes.hidden:'')} style={{'--bg':`url(${false?getMediaPath("tmp.png"):getMediaPath(window.DB?.idle.bg)}`}} onClick={startVideo}>
                <div className="btn">{window.DB?.idle.btn.Get("de")} <small>{window.DB?.idle.btn.Get("en")}</small></div>
           </div>
        </div>
    );
}
