import { useEffect, useRef, useState } from 'react';
import classes from './AppSelector.module.css';
import { useSelector } from 'react-redux';
import { availableApps, mmToPx, ppi } from './../../utils/generic';
import floor from '../../assets/floor.png';
import { HtmlKioskBridge, send } from './../../components/HtmlKioskBridge/HtmlKioskBridge';
import ScentTester from './../../components/ScentTester/ScentTester';
import OperatorOverlay from './OperatorOverlay/OperatorOverlay';

export default  function AppSelector() {
  const app = useSelector((state) => state.app?.app);
  const [opened, setOpened] = useState(false);
  const [openedStation, setOpenedStation] = useState(null);
  const [scentOpened, setScentOpened] = useState(false);
  const [_ppi, setPpi] = useState(ppi);
  const [udpReceived, setUdpReceived] = useState(null);
  const floorRef = useRef();
  useEffect(()=>{
    const keyDown = (e)=>{
      //console.log(e.key);
      if(e.key === "Escape" || e.key === " "){
        setOpened(!opened);
      }
    }

    if(opened){
      let ratio = 2200/1490;
      let {width, height} = floorRef.current.getBoundingClientRect();

      if(width/height > ratio){
        floorRef.current.style.width = height*ratio+"px";
        floorRef.current.style.height = height+"px";
      }else{
        floorRef.current.style.width = width+"px";
        floorRef.current.style.height = width/ratio+"px";
      }
    }
    else{
      setOpenedStation(null);
    }
    document.addEventListener("keydown", keyDown);
    return ()=>{
      document.removeEventListener("keydown", keyDown);
    }
  },[opened, setOpened])



   return (
    <div className={classes.root + " " + (!opened?classes.hidden:'') } >

      <div className={classes.content}>
        <h1>APP SELECTOR</h1>
        <div className={classes.entries}>
        {
          Object.values(availableApps).map((e,i)=>{
            return <div key={e.id+"_"+i} className={e.hash === app?.app?.hash?classes.active:''} onClick={()=>{
              document.location.hash = e.hash;
              document.location.reload();
            }}
            title={`${e.state} ${e.player} ${e.display}` }
            >{e.name || e.path}</div>
          })
        }
        </div>

      </div>
      <HtmlKioskBridge onUdp={(e)=>{setUdpReceived({ts:new Date().getTime(), data:e})}}></HtmlKioskBridge>
      <div className={classes.floor} style={{'--bg':`url(${floor.split("./").join("/")})`}} ref={floorRef}>
        {
          Object.values(availableApps).map((e,i)=>{
            return <div
              key={e.id+":"+i}
              className={
                (e.hash === app?.app?.hash?classes.active:'')+ " " +
                e.state + " " +
                classes[e.player?.toLowerCase()] + " "
              }
              style={{'--x':e.pos?.x, '--y':e.pos?.y}}
              onClick={(e1)=>{
                if(window.operator){

                    e1.preventDefault();
                    e1.stopPropagation();
                    setOpenedStation(e)


                }else{
                  document.location.hash = e.hash;
                  document.location.reload();
                }

              }}

            >
              <div className={classes.playerColor}></div>
              <div className={classes.ccontent}>
                {e.name?<><div><h3>{e.id}</h3>{e.name}</div></>:<p>{e.path}</p>}
              </div>
              <div className={classes.infos}>
                State: {e.state}<br/>
                Player: {e.player}<br/>
                Display: {e.display}<br/>
                {e.os && <>OS: {e.os}<br/></>}
                <div className={classes.operator}>
                  {e.player?.toLowerCase() === "brightsign" &&<>
                    Ip: {e.ip}<br/>
                    Mac: {e.mac}<br/>
                </>}
                </div>
                <i>{e.comment}</i></div>
            </div>
          })
        }


      </div>
      {window.operator && <OperatorOverlay udp={udpReceived} openedStation={openedStation}onStationClosed={()=>setOpenedStation(null)} stations={availableApps}></OperatorOverlay>}
      <div className={classes.scentBtn + " btn"} onClick={()=>setScentOpened(true)}>Duft tester</div>
      {scentOpened && <ScentTester onClose={()=>setScentOpened(false)}></ScentTester>}
    </div>
  );
}
