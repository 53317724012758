import { useEffect } from "react";
import { send } from "./HtmlKioskBridge/HtmlKioskBridge";

/*

init	1BE0E1E2E30D
open 1. 	1B40260D
close first	1BA6C00D
*/


export function ScentModule(){
  useEffect(()=>{

  },[]);
}

const channelMap = {
  "1_on":"40",
  "1_off":"C0",
  "2_on":"41",
  "2_off":"C1",
  "3_on":"42",
  "3_off":"C2",
  "4_on":"43",
  "4_off":"C3",
  "5_on":"44",
  "5_off":"C4",
  "6_on":"45",
  "6_off":"C5",
  "clear_1_on":"26",
  "clear_1_off":"A6",
  "7_on":"50",
  "7_off":"D0",
  "8_on":"51",
  "8_off":"D1",
  "9_on":"52",
  "9_off":"D2",
  "10_on":"53",
  "10_off":"D3",
  "11_on":"54",
  "11_off":"D4",
  "12_on":"55",
  "12_off":"D5",
  "clear_2_on":"36",
  "clear_2_off":"B6",
}


export const Open = ({channel, duration=1000}) =>{

  if(channel.toString() != parseInt(channel) || channel<1 || channel>12)
  {
    console.error("Channel must be a number: 1 - 12");
    return;
  }
  if(sendQueue.length>0){
    while(sendQueue.length>0)
      sendQueue.shift();
    Reset();
  }else{
    Init();
  }
  Send([channelMap[channel+"_on"], channelMap["clear_1_on"], channelMap["clear_2_on"]]);
  Send([channelMap[channel+"_off"], channelMap["clear_1_off"], channelMap["clear_2_off"]], duration);
}

export const Init = () =>{
  Send(["E0","E1", "E2", "E3"])
}
export const Reset = () =>{
  Send(["EE","EF"])
}


let sendQueue = [];
const Send = (msg, delay=0) =>{
  //console.log("Send", msg, delay);
  if(Array.isArray(msg))
    msg = msg.join("");

  if(delay < 200 && sendQueue.length > 0)
    delay += 200;
  if(sendQueue.length > 0 && sendQueue[sendQueue.length-1].ts >= new Date().getTime()+delay)
    delay = sendQueue[sendQueue.length-1].ts - new Date().getTime() + 200;
  sendQueue.push({msg, ts:new Date().getTime()+delay});


}

const sendNextInQueue = () =>{
  let msg = sendQueue.length>0?sendQueue[0]:null;
  if(!msg || msg.ts > new Date().getTime())
    return;
  sendQueue.shift();
  console.log("Sending", new Date().getTime()/1000, msg.msg);
  console.log("Queue", [...sendQueue]);
  send("serial", {
    payload:{
      payload:{
        msg:"1B"+msg.msg+"0D",
        type:"hex"
      }
    }
  });
}
let interval = setInterval(sendNextInQueue, 50);
