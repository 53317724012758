import React, { Suspense, useEffect } from 'react';
import './App.css';
import { loadActualApp, mmToPx } from './utils/generic';
import { idleOff, idleOn, setApp, setLanguage } from './_redux/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import AppSelector from './components/AppSelector/AppSelector';

import StartupVersionInfo from './components/StartupVersionInfo/StartupVersionInfo';
import { SfxPlayer } from './components/SfxPlayer/SfxPlayer';
import App_1 from './1/App_1';


const App_2 = React.lazy(() => import('./2/App_2'));
const App_3 = React.lazy(() => import('./3/App_3'));
const App_4_3 = React.lazy(() => import('./4.3/App_4_3'));
const App_5_1 = React.lazy(() => import('./5.1/App_5_1'));
const App_5_2 = React.lazy(() => import('./5.2/App_5_2'));
const App_6_1_a = React.lazy(() => import('./6.1.a/App_6_1_a'));
const App_6_1_b = React.lazy(() => import('./6.1.b/App_6_1_b'));
const App_6_1_c = React.lazy(() => import('./6.1.c/App_6_1_c'));
const App_6_1_d = React.lazy(() => import('./6.1.d/App_6_1_d'));
const App_6_1_e = React.lazy(() => import('./6.1.e/App_6_1_e'));
const App_6_1_f = React.lazy(() => import('./6.1.f/App_6_1_f'));
const App_6_1_g = React.lazy(() => import('./6.1.g/App_6_1_g'));
const App_6_2_a = React.lazy(() => import('./6.2.a/App_6_2_a'));
const App_7 = React.lazy(() => import('./7/App_7'));

let idleTo;

window.viewPort = {w:window.innerWidth, h:window.innerHeight};
window.hideGlobalIdle = false;

function App() {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app?.app);
  const {idleMode, debug} = useSelector((state) => state.app);
  const [rotation, setRotation] = React.useState(0);
  const setIdleOff = ()=>{
    clearTimeout(idleTo);
    if(!idleMode){
      clearTimeout(idleTo);
      if(app?.data?.settings?.timeout != null){
        idleTo = setTimeout(()=>{
          if(!window.disableIdle)
            dispatch(idleOn());
        }, app?.data?.settings.timeout*1000)
      }
    }

    dispatch(idleOff());
  }


  useEffect(()=>{
    if(idleMode){
      dispatch(setLanguage("de"));
    }
  },[idleMode]);

  useEffect(() => {
    document.title = "SENSORIA - "+(app?.app?.id)+" "+(app?.app?.name || app?.app?.path || "");

    if(document.location.search?.indexOf("offline") > -1)
      window.offline = true;
    if(document.location.search?.indexOf("brightsign") > -1 || navigator.userAgent.indexOf("BrightSign") > -1){
      window.device = "brightsign";
    }
    else if(document.location.search?.indexOf("dvxpro") > -1 || navigator.userAgent.indexOf("dvxpro") > -1)
      window.device = "dvxpro";
    else
      window.device = "pc";



    if(document.location.search?.indexOf("operator") > -1){
      window.operator = true;
      document.body.classList.add("operator");
    }

    document.body.classList.add(window.device);

    window.appSize = app?.app?.size || {width:1920, height:1080}
    if(window.appSize.width  != window.innerWidth || window.appSize.height != window.innerHeight){
      window.previewMode = true;
      document.body.classList.add("preview");
    }

    if(app?.app?.rotation){
      setRotation(app.app.rotation)

    }

    if(app?.data?.settings?.timeout != null)
      setIdleOff();
  },[app]);

  useEffect(() => {
    loadActualApp((app)=>dispatch(setApp(app)));
  },[]);

  useEffect(()=>{
    document.addEventListener("mousedown", setIdleOff);
    document.addEventListener("touchstart", setIdleOff);
    return ()=>{
      document.removeEventListener("mousedown", setIdleOff);
      document.removeEventListener("touchstart", setIdleOff);
    }
  },[setIdleOff])


  return (
    <>
      <div className={"color_0 App " + (debug?"debug":'')} style={{"--w":(app?.app?.size?.width || 1920)+"px", "--h":(app?.app?.size?.height || 1080)+"px", "--r":rotation+"deg"}} >
        {!app?.app?.id && <div>Loading...</div>}
        <SfxPlayer></SfxPlayer>
        {app?.app?.id?.indexOf("1") === 0 && <Suspense fallback={<div>Loading 1...</div>}><App_1 app={app}></App_1></Suspense>}
        {app?.app?.id?.indexOf("2.") === 0 && <Suspense fallback={<div>Loading 2...</div>}><App_2 app={app}></App_2></Suspense>}

        {app?.app?.id?.indexOf("3.") === 0 && <Suspense fallback={<div>Loading 3.1</div>}><App_3 app={app}></App_3></Suspense>}

        {app?.app?.id === "4.3" && <Suspense fallback={<div>Loading 4.3</div>}><App_4_3 app={app}></App_4_3></Suspense>}

        {app?.app?.id?.indexOf("5.1") === 0 && <Suspense fallback={<div>Loading 5.1</div>}><App_5_1 app={app}></App_5_1></Suspense>}

        {app?.app?.id?.indexOf("5.2") === 0 && <Suspense fallback={<div>Loading 5.2</div>}><App_5_2 app={app}></App_5_2></Suspense>}

        {app?.app?.id === "6.1.a" && <Suspense fallback={<div>Loading 6.1</div>}><App_6_1_a app={app}></App_6_1_a></Suspense>}

        {app?.app?.id?.indexOf("6.1.b") === 0 && <Suspense fallback={<div>Loading 6.1b</div>}><App_6_1_b app={app}></App_6_1_b></Suspense>}
        {app?.app?.id === "6.1.c" && <Suspense fallback={<div>Loading 6.1c</div>}><App_6_1_c app={app}></App_6_1_c></Suspense>}

        {app?.app?.id === "6.1.d" && <Suspense fallback={<div>Loading 6.1d</div>}><App_6_1_d app={app}></App_6_1_d></Suspense>}

        {app?.app?.id === "6.1.e" && <Suspense fallback={<div>Loading 6.1e</div>}><App_6_1_e app={app}></App_6_1_e></Suspense>}

        {app?.app?.id === "6.1.f" && <Suspense fallback={<div>Loading 6.1f</div>}><App_6_1_f app={app}></App_6_1_f></Suspense>}

        {app?.app?.id === "6.1.g" && <Suspense fallback={<div>Loading 6.1g</div>}><App_6_1_g app={app}></App_6_1_g></Suspense>}
        {app?.app?.id === "6.2.a" && <Suspense fallback={<div>Loading 6.2a</div>}><App_6_2_a app={app}></App_6_2_a></Suspense>}
        {app?.app?.id?.indexOf("7") === 0 && <Suspense fallback={<div>Loading 7</div>}><App_7 app={app}></App_7></Suspense>}


        {!window.hideGlobalIdle && idleMode && <div className="idle"></div>}


      </div>
      <AppSelector></AppSelector>
      <StartupVersionInfo></StartupVersionInfo>
    </>
  );
}

export default App;
